import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {InputCheckbox, InputLocation, InputText, Select} from '@evag/ec-react';

import Button from './Button';

import * as styles from './CampaignForm.module.scss';

import bairrosSP from '../resources/bairros-sp';

const CampaignForm = ({values, onChange, onBlur, setFieldValue}) => {
    const props = name => ({name, value: values[name], onChange, onBlur});

    return (
        <>
            <InputText
                className={styles.text}
                placeholder="Nome completo"
                {...props('name')}
            />
            <InputText
                className={styles.text}
                placeholder="E-mail"
                type="email"
                {...props('email')}
            />
            <InputText
                className={styles.text}
                cleave={{blocks: [0, 2, 9], delimiters: ['(', ') '], numericOnly: true}}
                placeholder="WhatsApp"
                type="tel"
                {...props('phone')}
            />
            <InputText
                className={styles.text}
                placeholder="Movimento/Coletivo"
                {...props('orgName')}
            />
            <Select
                className={styles.text}
                placeholder="Bairro"
                options={bairrosSP}
                {...props('district')}
            />
            {
                props('district').value === "-" &&
                <InputLocation
                    className={styles.select}
                    names={['uf', 'city']}
                    setFieldValue={setFieldValue}
                    {...{values, onChange, onBlur}}
                />
            }
            <InputCheckbox
                className={styles.checkbox}
                label="Eu autorizo a publicação do meu nome como apoiador(a) da iniciativa SP é SoloPreto."
                {...props('publicName')}
            />

            <InputCheckbox
                className={styles.checkbox}
                label="Quero receber novidades."
                {...props('subscribe')}
            />

            <div className={styles.buttons}>
                <p className={styles.privacidade}>
                    <em>Veja nossa <a id="btn-privacidade-form" href="/privacidade">Política de Privacidade</a>.<br/>Este
                        site é protegido pelo reCAPTCHA e, por isso, a <a href="https://policies.google.com/privacy">política
                            de privacidade</a> e os <a href="https://policies.google.com/terms">termos de serviço</a> do
                        Google também se aplicam.</em>
                </p>
                <Button id="btn-submissao" type="submit" fontSize="16px" bg="#fff" fg="#333">Participar</Button>
            </div>
        </>
    );
};


CampaignForm.initialValues = {
    name: '',
    email: '',
    phone: '',
    orgName: '',
    district: '',
    uf: '',
    city: '',
    publicName: false,
    subscribe: false,
};

CampaignForm.validationSchema = Yup.object().shape({
    name: Yup.string().required('O campo Nome é obrigatório.'),
    email: Yup.string().email('Digite um e-mail válido.').required('O campo E-mail é obrigatório.'),
    phone: Yup.string(),
    orgName: Yup.string(),
    district: Yup.string(),
    uf: Yup.string(),
    city: Yup.string(),
    publicName: Yup.bool(),
    subscribe: Yup.bool(),
});

CampaignForm.propTypes = {
    values: PropTypes.object.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

CampaignForm.captcha = true;
CampaignForm.element = CampaignForm;

export default CampaignForm;
